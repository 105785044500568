.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #f8f9fa; */
    padding: 1rem;
    gap: 1rem;
  }
  
  .footer__link {
    color: #686868;
    text-decoration: none;
  }
  
  .footer__link:hover {
    text-decoration: underline;
  }
  