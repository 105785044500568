.header-article-cta {
    height: 80px;
    width: 100%;
    background-color: #f1fbf1;
    display: flex;
    align-items: center;
  }
  
  .header-article-cta__content {
    max-width: 700px;
    width: 100%;
    margin: 0 auto;
    padding: 0 16px;
    gap: 8px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .header-article-cta__text {
    font-size: 0.9rem;
    max-width: 260px;
    font-weight: 500;
    color: #333;
  }
  
  .header-article-cta__cta {
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #1BD242; /* Adjust the color as needed */
    font-weight: bold;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    white-space: nowrap; /* Prevents the text from wrapping */
  }
  
  .header-article-cta__cta:hover {
    background-color: #21d748; /* Darken the button on hover */
  }
  



  /* Responsive adjustments for mobile devices */
  @media (max-width: 320px) {
    .header-article-cta{
      height: auto;
      padding: 10px 0;
    }
  
    .header-article-cta__content {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  
    .header-article-cta_text {
      margin-bottom: 10px;
    }
  
    .header-article-cta__cta {
      width: 100%;
      max-width: 300px;
    }
  }
  