.progress-bar {
    width: 100%;
    height: 6px;
    background-color: #efefef;
    position: relative;
  }
  
  .progress-bar__completed {
    height: 100%;
    background-color: #4caf50;
    position: absolute;
    left: 0;
    top: 0;
    max-width:100%;
  }