.reassurance-page {
    text-align: center;
    animation: fadeInSlideIn 0.3s ease-out forwards;
    max-width: 480px;
    margin: 0 auto;
  }
  .reassurance-page h1 {
    margin-top: 30px;
    font-size: 1.25rem;
    font-weight: 800;
    color: #4db050;

  }
  .reassurance-page h2 {
    font-size: 1rem;
  }
  .reassurance-page-padding {
    /* existing styles */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
}
.reassurance-page__disclaimer {
  font-size: 0.75rem;
  color: rgba(0,0,0,0.7);
}
.reassurance-page-padding img {
    max-width: 320px;
    width: 100%;
    height: auto;
}

.reassurance-page__image {
  max-width: 160px;
  height: 10px;
}
/* Add additional media queries as needed for responsiveness */
@media (max-width: 768px) {
    /* Adjust styles for smaller screens */
}

  .reassurance-page__text {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
  

  
  .reassurance-page__headline {
    font-size: 1.25rem
  
  
}
.reassurance-page__paragraph {
font-size: 14px;
text-align: left;
margin: 1rem 0;
}

.reassurance-page__list {
list-style-type: none;
padding: 0;
}

.reassurance-page__list li {
font-size: 1.1rem;
margin-bottom: 0.5rem;
}


.reassurance-page__continue {
    font-size: 1rem;
    font-weight: 600;
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    border-radius: 10rem;
    border: none;
    cursor: pointer;
    background-color: var(--button-color);
    color: white;
    transition: background-color 0.3s, color 0.3s;
    width: 100%;
    max-width: 600px;
    height: 60px;
}



.reassurance-page__continue:hover {
background-color: var(--button-hover-color);
}


.reassurance-page__go-back {
    display: block;
    font-size: 1rem;
    text-align: center;
    margin-top: 1rem;
    color: rgb(143, 143, 143);
    text-decoration: none;
  }
  
  .reassurance-page__go-back:hover {
    text-decoration: underline;
  }
  

  
  @media (min-width: 540px) {
    .reassurance-page__continue {
      max-width: 420px;
    }
  }