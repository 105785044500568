@import './variables.css';


.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: 'Inter', sans-serif;
}

body {
  font-family: 'Inter', sans-serif;
}

.App__content {
  max-width: 1200px;
  margin: 0 auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%; /* Add this line */
}


@media (max-width: 768px) {
  .hero {
    flex-direction: column-reverse;
    align-items: center;

  }

  .hero__content {
    max-width: 100%;
    text-align: center;
    padding: 0 1rem; /* Add some padding to the content on mobile */

  }

  .hero__image {
    max-width: 100%;
    margin-top: 0.1rem;
  }
  .hero__cta-btn {
    width: 100%;
    margin-bottom: 1rem; /* Add space between CTAs on mobile */
    border-radius: 100px;
  }
}

