/* src/components/Articles/Review.css */

.review {
    margin: 20px 0;
    background-color: rgba(0,0,0,0.05);
    border-radius: 8px;
    padding: 16px;
    position: relative;
    text-align: left;
}
.review h4 {
  margin: 0 auto;
}

.testimonial__stars img {
  width: 24px;
  height: 24px;
  padding: 2px;
}

.testimonial__verified {
  position: absolute;
  top: 16px;
  right: 16px;

  display: inline-flex;  /* Change this from 'flex' to 'inline-flex' */
  font-size: 0.6rem;
  align-items: center;
  gap: 4px;
  background-color: #aad3ad;
  padding: 8px 12px;
  border-radius: 4px;
}


.testimonial__verified img {
  width: 16px;
  height: 16px;
  align-items: center;
}

.testimonial__header {
  display: inline-block;
  padding: 0px 10px;
}

.review-photo {
  max-width: 100%;
}

.description {
  margin: 20px 0;
  color: rgba(0,0,0,0.9);
  line-height: 1.3em;
}
