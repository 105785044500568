.ai3-hero-container {
  text-align: center;
  padding: 20px;
  max-width: 360px;
  margin: 0 auto;
}

.ai3-hero-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.ai3-hero-subtitle {
  font-size: 14px;
  color: rgba(0,0,0,0.8);
  margin-bottom: 20px;
}

.ai3-hero-quiz-invite {
  font-size: 16px;
  color: #000000;
  font-weight: 600;
  margin-bottom: 20px;
}

.ai3-hero-gender-selection {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.ai3-gender-card {
  cursor: pointer;
  width: 320px; /* Set the default width */
  max-width: 100%; /* Ensure it doesn't exceed the width of its container */
  padding: 0px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  position: relative;
  overflow: visible;
  text-align: center; /* Align the contents of the gender card */
  margin-bottom: 20px; /* Add space between cards when they wrap */
}

.ai3-male-card {
  background-color: #BCE0FD; /* Example light blue background for the male card */
}

.ai3-female-card {
  background-color: #FAD0C4; /* Example pink background for the female card */
}

/* ... (additional styles for gender-card, gender-button, etc.) */

.ai3-gender-image-container {
  margin-bottom: -5px; /* Space between the image and the button */
}

.ai3-gender-card img {
  width: 128px;
  height: auto;
  margin-top: -40px; /* Move the image up */
  border-radius: 10px;
}

.ai3-hero-image {
  max-width: 280px;
  height: auto;
  margin-top: -40px; /* Move the image up */
  border-radius: 10px;
}

.ai3-gender-button {
  background-color: purple; /* Replace with actual color */
  color: white;
  border: none;
  border-radius: 5px;
  padding: 12px 20px;
  font-size: 14px;
  font-weight: bold;
  display: block; /* Block display to fill the parent width */
  margin: 0 auto; /* Center the button if the card width is variable */
}

.ai3-male-button {
  background-color: #4DB051; /* Replace with actual color */
}
.ai3-female-button {
  background-color: rgb(197, 22, 81); /* Replace with actual color */
}
/* Add media queries and additional styling as needed */
.ai3-gender-card:hover {
  transform: scale(1.05);
}

@media (max-width: 420px) {
  .ai3-gender-card {
    width: auto; /* Allows the card to shrink */
  }
}

@media (max-width: 400px) {
  .ai3-gender-button {
    min-height: 60px; /* Adjust this value based on the larger button */
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
