.landing_disclaimer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-color: #f8f9fa; */
    padding: 1rem;
    gap: 1rem;
    font-size: 12px;
    color: #a8a8a8;
    text-align: center;
    max-width: 480px;
    margin: 0 auto;
}
  
  .landing_copyright {
    font-size: 14px;
    color: #686868;
    margin: 0px;
  }
  .landing_disclaimer__link {
    color: #686868;
    text-decoration: none;
  }
  
  .landing_disclaimer__link:hover {
    text-decoration: underline;
  }
  