.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  max-width: 1200px;
}

.header__logo {
  max-height: 50px;
}

.header__counter {
  font-size: 16px;
}
