/* src/components/Articles/Disclaimer.css */

.disclaimer {
    font-size: 12px !important;
    text-align: center;
    margin: 20px 0;
    color: #8f8f8f;
  }

.policy-links {
  font-size: 14px !important;
}