.header-quiz {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    max-width: 1000px;
    margin: 0 auto;
  }

  .header-quiz_wrapper {
    background-color: #f8f9fa;
  }
  
  .header-quiz__logo {
    max-height: 50px;
  }
  
  .header-quiz__counter {
    font-size: 16px;
  }
  
  