/* src/components/Button.css */

.button {
    width: 100%;
    padding: 16px;
    background-color: #1bd242;
    color: white;
    border: none;
    cursor: pointer;
    margin: 20px 0;
    font-size: 1.25em;
    font-weight: 900;
    border-radius: 8px;
  }
  