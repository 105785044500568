/* src/components/Articles/Article.css */

.article {
    max-width: 700px;
    margin: 0 auto;
    padding: 16px;
  
}
.article h2 {
  font-size: 2em;
  margin-bottom: 10px;
}
.article p {
  font-size: 1.2em;
}

