/* src/components/Articles/AuthorBlock.css */

.author-block {
    display: flex;
    align-items: center;
    margin: 20px 0;
    margin-bottom: 0px;

  }
  
  .author-block img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .author-block p {
    font-size: 1em;
    color: rgba(0,0,0,0.7);
  }